import { makeAutoObservable } from 'mobx'
import { jwtDecode } from 'jwt-decode'

class User {
    constructor() {
        const token = localStorage?.getItem('token') || null
        this._isAuth = !!localStorage?.getItem('token')
        this._user = token ? jwtDecode(token) : {}
        makeAutoObservable(this)
    }
    setIsAuth(bool) {
        this._isAuth = bool
    }
    setSubject(token) {
        const user = token ? jwtDecode(token) : {}
        this._user = user
    }
    get isAuth() {
        return this._isAuth
    }
    get subject() {
        return this._user
    }
}

export default User;