import { makeAutoObservable } from 'mobx'

class Client {
    _isFound = false;
    _client = {};

    constructor() {
        makeAutoObservable(this)
    }
    setIsFound(bool) {
        this._isFound = bool
    }
    setSubject(client) {
        this._client = client
    }
    get isFound() {
        return this._isFound
    }
    get subject() {
        return this._client
    }
}

export default Client;