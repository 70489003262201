import { makeAutoObservable } from 'mobx'

class Toaster {
    constructor() {
        this._toasts = []
        makeAutoObservable(this)
    }
    pushToast(toast) {
        this._toasts.push(toast)
    }
    removeToast(toast) {
        this._toasts = [...this._toasts.filter(el=>el.id !== toast.id )]
    }
    get toasts() {
        return this._toasts
    }
}

export default Toaster;