export default {
    nothingPage: {
        1: 'Страница',
        2: 'не',
        3: 'существует'
    },
    authPage: {
        title: 'Войдите в приложение',
        placeholder: {
            1: 'Пожалуйста введите',
            2: 'логин',
            3: 'и',
            4: 'пароль'
        },
        labelUsername: 'Логин',
        labelPassword: 'Пароль',
        keepSigned: 'Оставаться авторизованным',
        submitButton: 'Далее'
    },
    userShield: {
        logout: 'Нажмите для выхода',
        doctor: '(Врач)',
        user: '(Пользователь)',
        unauthorize: 'Не авторизован'
    },
    clientOffcanvas: {
        empty: 'Не заполнено',
        searchClient: 'Поиск пациента',
        searchResult: 'Результаты поиска',
        buttonSubmit: 'Поиск',
        clientId: 'ID',
        birthday: 'Дата рождения',
        surname: 'Фамилия',
        name: 'Имя',
        patronymic: 'Отчество',
        snils: 'СНИЛС',
        insurance: 'Полис ОМС',
        insuranceShort: 'Полис',
        passport: 'Паспорт',
        address: 'Адрес',
        phone: 'Телефон',
        email: 'E-mail',
        backSearch: 'Назад, к поиску',
        goClientCard: 'Перейти к карте пациента',
        scanQr: 'Отсканируйте QR код и вводите данные в карту с мобильного телефона :)',
        modify: 'Редактировать пациента',
        delete: 'Удалить пациента',
        back: 'К главному экрану'
    },
    calendar: {
        all: 'Все',
        speciality: 'Специальность',
        date: 'Дата',
        free: 'Свободно',
        phone: 'Телефон',
        snils: 'СНИЛС',
        cancel: 'Отменить запись',
        queueCard: {
            title: 'График не настроен',
            text: 'Необходимо создать очереди на врачей, тогда здесь появятся номерки',
            button: 'Создать очередь'
        }
    },
    tabPanelCard: {
        tabs: {
            tickets: 'Взятые номерки',
            params: 'Витальные параметры',
            ambulance: 'Амбулаторная карта',
            dicom: 'DICOM'
        },
        getTicket: 'Записать на прием',
        getReferral: 'Выдать направление',
        queueName: 'Осн. очередь',
        referralName: 'Направления',
        ticketPlaceholder: {
            1: 'Для удобного разделения потоков пациентов,',
            2: 'могут появиться другие очереди'
        },
        params: 'Параметры',
        addParam: 'Добавить параметр',
        refresh: 'Обновить',
        paramsPlaceholder: 'Выберите параметр, а мы построим график!',
        printParam: 'Напечатать',
        addAmbulance: 'Добавить лист амблуторной карты',
        ambulance: 'Листы осмотра',
        ambulancePlaceholder: "Выберите лист, посмотрим, что там",
        modifyAmbulance: 'Редактировать лист осмотра',
        deleteAmbulance: 'Удалить лист осмотра',
        emptyTitle: 'Нет названия',
        ambulanceBadge: {
            user: 'Пользователь:',
            remark: 'Название?',
            status: 'Статус',
            typeList: 'Тип',
        }
    },
    queueForm: {
        backwardRoot: 'Назад в население',
        title: 'Создание очереди',
        select: 'Выберите',
        hint: {
            0: 'Вы создаете очередь для врача',
            1: ', вот номерки:'
        },
        submit: 'Создать очередь'
    },
    buttonConfirm: {
        hint: 'Для подвтерждения введите код'
    },
    bottomBar: {
        queueManager: 'Администратор очередей',
        createQueue: 'Создать очередь',
        gzButton: 'Запись в другие ЛПУ',
        updateDirectorySpec: 'Редактировать специальности',
        updateDirectoryMed: 'Редактировать врачей',
        openProfile: 'Редактировать профили',
    },
    hubAppointment: {
        title: 'Запись в другие ЛПУ',
        createReferral: 'Создать направление',
        freeAppointment: 'Свободная запись',
        referralAppointment: 'Запись по направлению',
        editProfiles: 'Редактировать профили',
        specialty: 'Специальности',
        selectSpecialty: 'Выберите специальность',
        doctor: 'Врач',
        selectDoctor: 'Выберите врача',
        planeDate: 'Плановая дата',
        diagnos: 'Диагноз',
        comment: 'Комментарий',
        profile: 'Профили',
        selectProfile: 'Выберите профиль',
        dateStart: 'Дата начала',
        dateEnd: 'Дата окончания',
        editProfile: 'Изменить профиль',
        areaMedical: 'Район, ЛПУ',
        selectAreaMedical: 'Выберити район и ЛПУ',
        specialtyDoctor: 'Специальность, врач',
        selectSpecialtyDoctor: 'Выберите специальность и врача',
        dateTime: 'Дата, время',
        selectDateTime: 'Выберите дату и время',
        selectTime: 'Выберите время',
        confirm: 'Подтверждение',
        pressButton: 'Нажмите кнопку для подтверждения',
        makeAppointment: 'Записать',
        profileHasChanged: 'Профиль был изменен',
        result: 'Результат',
        resultError: 'Что-то пошло не так',
        resultSuccess: 'Пациент успешно записан',
        surname: 'Фамилия пациента',
        number: 'Номер направления',
        formData: 'Данные по направлению',
        typeThis: 'Заполните форму',
        next: 'Вперед',
    },
    portal: {
        freeAppointment: 'Свободная запись',
        referralAppointment: 'Запись по направлению',
        cancelAppointment: 'Отменить запись',
        appointment: 'Запись',
        cancel: 'Отменить',
        available: 'Свободная',
        byReferral: 'По направлению',
        anAppointment: 'Запись'
    }
}