{
  "name": "new-nasel",
  "version": "2.1.3",
  "description": "",
  "scripts": {
    "start": "dotenvx run -f ./envelopment/.env.$NODE_ENV -- npx parcel ./public/index.html",
    "build": "dotenvx run -f ./envelopment/.env.$NODE_ENV -- npx parcel build ./public/index.html ",
    "build-all": "./scripts/build-all.sh",
    "clean": "rm -rf ./dist ./.parcel-cache"
  },
  "dependencies": {
    "@ahmedhamdan/dubai-font": "^1.0.0",
    "@dotenvx/dotenvx": "^0.37.1",
    "axios": "^1.5.0",
    "bootstrap": "^5.3.2",
    "dotenv": "^16.4.5",
    "formik": "^2.4.5",
    "gost-transliteration": "^0.1.2",
    "i18next-browser-languagedetector": "^7.1.0",
    "iconv-lite": "^0.6.3",
    "jwt-decode": "^4.0.0",
    "mobx": "^6.10.2",
    "mobx-react-lite": "^4.0.4",
    "preact": "^10.20.1",
    "qs": "^6.11.2",
    "react": "^18.2.0",
    "react-bootstrap": "^2.8.0",
    "react-chartjs-2": "^5.2.0",
    "react-dom": "^18.2.0",
    "react-i18next": "^13.2.2",
    "react-ionicons": "^4.2.1",
    "react-multi-date-picker": "^4.4.1",
    "react-qr-code": "^2.0.12",
    "react-router-dom": "^6.16.0",
    "react-select": "^5.8.0"
  },
  "devDependencies": {
    "@parcel/packager-xml": "^2.9.3",
    "@parcel/reporter-bundle-analyzer": "^2.12.0",
    "@parcel/transformer-sass": "^2.12.0",
    "@parcel/transformer-svg-react": "^2.12.0",
    "@parcel/transformer-xml": "^2.9.3",
    "buffer": "^6.0.3",
    "crypto-browserify": "^3.12.0",
    "events": "^3.3.0",
    "parcel": "^2.12.0",
    "process": "^0.11.10",
    "stream-browserify": "^3.0.0",
    "string_decoder": "^1.3.0"
  }
}
