export default {
    nothingPage: {
        1: 'Page',
        2: "doesn't",
        3: 'exist'
    },
    authPage: {
        title: 'Sign in to App',
        placeholder: {
            1: 'Please enter your',
            2: 'username',
            3: 'and',
            4: 'password'
        },
        labelUsername: 'Username',
        labelPassword: 'Password',
        keepSigned: 'Keep me signed in',
        submitButton: 'Next'
    },
    userShield: {
        logout: 'Press to exit',
        doctor: '(Doctor)',
        user: '(User)',
        unauthorize: 'No authorize'
    },
    clientOffcanvas: {
        empty: 'Empty',
        searchClient: 'Patient search',
        searchResult: 'Searching results',
        buttonSubmit: 'Search',
        clientId: 'Client ID',
        birthday: 'Birthday',
        surname: 'Surname',
        name: 'Name',
        patronymic: 'Patronymic',
        snils: 'SNILS',
        insurance: 'Health Insurance Policy',
        insuranceShort: 'H.I. Policy',
        passport: 'ID / Passport',
        address: 'Address',
        phone: 'Phone',
        email: 'E-mail',
        backSearch: 'Back to search',
        goClientCard: 'Go to patient card',
        scanQr: 'Scan the QR code and enter data into the card from your mobile phone :)',
        modify: 'Edit patient',
        delete: 'Delete patient',
        back: 'Back to main screen'
    },
    calendar: {
        all: 'All',
        speciality: 'Specialty',
        date: 'Date',
        free: 'Available',
        phone: 'Phone',
        snils: 'SNILS',
        cancel: 'Cancel an appointment',
        queueCard: {
            title: 'The queue is not configured',
            text: 'It is necessary to create queue for apointment, then slots will appear here',
            button: 'Create a queue'
        }
    },
    tabPanelCard: {
        tabs: {
            tickets: 'Taken slots',
            params: 'Params',
            ambulance: 'Ambulance card',
            dicom: 'DICOM'
        },
        getTicket: 'Make an appointment',
        getReferral: 'Create a referral',
        queueName: 'Main queue',
        referralName: 'Referrals',
        ticketPlaceholder: {
            1: 'For convenient separation of patient flows,',
            2: 'other queues may appear'
        },
        params: 'Parameters',
        addParam: 'Add parameter',
        refresh: 'Refresh',
        paramsPlaceholder: 'Select a parameter and we will build a graph!',
        printParam: 'Print',
        addAmbulance: 'Add ambulance card item',
        ambulance: 'Ambulance card',
        ambulancePlaceholder: "Select a sheet, let's see what's there",
        modifyAmbulance: 'Edit ambulance card',
        deleteAmbulance: 'Delete ambulance card',
        emptyTitle: 'Empty title',
        ambulanceBadge: {
            user: 'User:',
            remark: 'Remark',
            status: 'Status',
            typeList: 'TypeList',
        }
    },
    queueForm: {
        backwardRoot: 'Go back to start window',
        title: 'Creating a queue',
        select: 'Select',
        hint: {
            0: 'You are creating a queue for the appointment',
            1: ', here are the slots:'
        },
        submit: 'Create a queue'
    },
    buttonConfirm: {
        hint: 'To confirm the action, enter the code'
    },
    bottomBar: {
        queueManager: 'Queue Manager',
        createQueue: 'Create a queue',
        gzButton: 'Intergration module',
        updateDirectorySpec: 'Edit specialties',
        updateDirectoryMed: 'Edit doctors',
        openProfile: 'Edit healthcare profiles',
    },
    hubAppointment: {
        title: 'Integration module',
        createReferral: 'Create a referral',
        freeAppointment: 'Available appointment',
        referralAppointment: 'Appointment by referral',
        editProfiles: 'Edit healthcare profiles',
        specialty: 'Speciality',
        selectSpecialty: 'Select a specialty',
        doctor: 'Doctor',
        selectDoctor: 'Select a doctor',
        planeDate: 'Plane date',
        diagnos: 'Diagnos',
        comment: 'Comment',
        profile: 'Healthcare profile',
        selectProfile: 'Select a profile',
        dateStart: 'Date start',
        dateEnd: 'Date end',
        editProfile: 'Edit helthcare profile',
        areaMedical: 'Area, medical',
        selectAreaMedical: 'Select area and medical',
        specialtyDoctor: 'Specialty, doctor',
        selectSpecialtyDoctor: 'Select specialty and doctor',
        dateTime: 'Date, time',
        selectDateTime: 'Select date and time',
        selectTime: 'Select time',
        confirm: 'Confirm',
        pressButton: 'Fill the form and press button',
        makeAppointment: 'Make an appointment',
        profileHasChanged: 'Helathcare profile has been channged',
        result: 'Result',
        resultError: 'Something went wrong',
        resultSuccess: 'Successfullly',
        surname: 'Patient surname',
        number: 'Referral number',
        formData: 'Referral form',
        typeThis: 'Type this form',
        next: 'Go next',
    },
    portal: {
        freeAppointment: 'Available appointment',
        referralAppointment: 'Appointment by referral',
        cancelAppointment: 'Cancel an appointment',
        appointment: 'Appointment',
        cancel: 'Cancel',
        available: 'Available',
        byReferral: 'By referral',
        anAppointment: 'An appointment'
    }
}