import "./utils/i18n";
import { BrowserRouter } from 'react-router-dom'
import { createRoot } from 'react-dom/client'
import User from './store/User'
import Client from './store/Client'
import Toaster from './store/Toaster';
import React from 'react'
import { name, version, description } from '../package.json'

if (process.env.CSS_FILE) import('./scss/' + process.env.CSS_FILE).then(() => console.info('Added custom SCSS'))
else import('./scss/default.scss')

if (process.env.APP_FILE) console.info('Added custom APP')

document.title = process.env.TITLE || 'BCD-tech'

window.settingsModule = { name, version, description }

export const Context = React.createContext(null)

async function start() {
    const App = await import('./' + (process.env.APP_FILE || 'App'))

    const root = createRoot(document.getElementById('root'))
    root.render(<Context.Provider
        value={{ user: new User(), client: new Client(), toaster: new Toaster() }}
    >
        <BrowserRouter>
            <App.default />
        </BrowserRouter>
    </Context.Provider>)
}

start()
